import React, { Component } from "react";

interface Download {
  type: string;
  version: string;
  build: string;
  releaseDate: string;
  path: string;
  name: string;
}

interface Props {
  download: Download;
}
class DownloadRow extends Component<Props, any> {
  render() {
    return (
      <tr>
        <td>{this.props.download.type}</td>
        <td>{this.props.download.version}</td>
        <td>{this.props.download.build}</td>
        <td>{this.props.download.releaseDate}</td>
        <td className="text-center">
          <a
            className="btn btn-primary"
            title={this.props.download.name}
            href={"citopus/downloads/" + this.props.download.name}
          >
            Download
          </a>
        </td>
      </tr>
    );
  }
}

export default DownloadRow;
