import React, { Component } from "react";
import Registration from "./components/pages/Authentication";
import Downloads from "./components/pages/Downloads";
import ReleaseNotes from "./components/pages/ReleaseNotes";
import PasswordReset from "./components/pages/PasswordReset";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

class App extends Component {
  render = () => (
    <Router>
      <div className="container-fluid">
        <Switch>
          <Route exact path="/" component={Registration} />
          <Route exact path="/downloads">
            <Downloads />
          </Route>
          <Route exact path="/releaseNotes">
            <ReleaseNotes />
          </Route>
          <Route exact path="/passwordReset">
            <PasswordReset />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
