import React, { Component, FormEvent, Fragment } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { baseURL } from "../../data/baseURL";

interface State {
  email: string;
  verificationCode: string;
  password: string;
  passwordTwo: string;
  emailSent: boolean;
  codeVerified: boolean;
  success: string;
}

class PasswordReset extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      verificationCode: "",
      password: "",
      passwordTwo: "",
      emailSent: false,
      codeVerified: false,
      success: "",
    };
  }

  sendResetCode = async () => {
    const config = {
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        baseURL + "/api/users/sendReset",
        { email: this.state.email },
        config
      );

      const passToken = response.data.token;

      if (passToken) {
        localStorage.setItem("passToken", passToken);
        this.setState({ emailSent: true });
      } else {
        console.log("No token in reponse.");
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  submitVerificationCode = async () => {
    const token = localStorage.getItem("passToken") || "";
    const config = {
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    try {
      const response = await axios.post(
        baseURL + "/api/users/resetCheck",
        { passwordResetCode: this.state.verificationCode },
        config
      );
      if (response.data.verified) {
        this.setState({ codeVerified: true });
      } else {
        alert("Incorrect Code.");
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  sendNewPassword = async () => {
    localStorage.removeItem("passToken");

    const config = {
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        baseURL + "/api/users/reset",
        { password: this.state.password, email: this.state.email },
        config
      );

      if (response.data.passwordReset) {
        this.setState({
          success:
            "Password Successfully Reset, Redirecting You to the Login Page. Good Luck!",
        });
        setTimeout(() => {
          this.props.history.push("/");
        }, 3000);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!this.state.emailSent) {
      if (!this.state.email) {
        alert("Please Fill Out Email Field!");
      } else {
        this.sendResetCode();
      }
    }
    if (this.state.verificationCode && !this.state.codeVerified) {
      this.submitVerificationCode();
    }
    if (this.state.codeVerified) {
      if (!this.state.password || !this.state.passwordTwo) {
        alert("Please Enter Your New Password Twice");
      } else if (this.state.password !== this.state.passwordTwo) {
        alert("Passwords are not equal!");
      } else {
        this.sendNewPassword();
      }
    }
  };

  render() {
    return (
      <div className="m-5 row">
        <div className=" offset-4 text-left col-4">
          <h2>Reset Password</h2>
          {!this.state.success ? (
            <form onSubmit={this.onSubmit}>
              {!this.state.emailSent ? (
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    value={this.state.email}
                    onInput={(e: FormEvent<HTMLInputElement>) =>
                      this.setState({ email: e.currentTarget.value })
                    }
                  ></input>
                </div>
              ) : (
                !this.state.codeVerified && (
                  <Fragment>
                    <div className="form-group">
                      <label htmlFor="verificationCode">
                        Verification Code
                      </label>
                      <input
                        type="text"
                        name="verificationCode"
                        className="form-control"
                        value={this.state.verificationCode}
                        onInput={(e: FormEvent<HTMLInputElement>) =>
                          this.setState({
                            verificationCode: e.currentTarget.value,
                          })
                        }
                      ></input>
                    </div>
                    <p>
                      Please check your email for the code and submit it here.
                    </p>
                  </Fragment>
                )
              )}
              {this.state.codeVerified && (
                <Fragment>
                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      value={this.state.password}
                      onInput={(e: FormEvent<HTMLInputElement>) =>
                        this.setState({ password: e.currentTarget.value })
                      }
                    ></input>
                    <label className="mt-1" htmlFor="passwordTwo">
                      Verify New Password
                    </label>
                    <input
                      type="password"
                      name="passwordTwo"
                      className="form-control"
                      value={this.state.passwordTwo}
                      onInput={(e: FormEvent<HTMLInputElement>) =>
                        this.setState({ passwordTwo: e.currentTarget.value })
                      }
                    ></input>
                  </div>
                </Fragment>
              )}
              <button className="btn btn-dark mt-4" type="submit">
                {!this.state.emailSent && "Send Reset Code"}
                {this.state.emailSent &&
                  !this.state.codeVerified &&
                  "Submit Code"}
                {this.state.codeVerified && "Set Password"}
              </button>
              <div className="mt-2">
                <Link to="/">Nevermind, I remember now!</Link>
              </div>
            </form>
          ) : (
            <h2 className="text-success">{this.state.success}</h2>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(PasswordReset);
