import React, { Component, FormEvent, SyntheticEvent } from "react";
import axios from "axios";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { baseURL } from "../../data/baseURL";

interface updateLicenseState {
  newLicense: string;
  formError: string;
}

interface updateLicenseProps extends RouteComponentProps {
  emailId: string;
  password: string;
}

class UpdateLicense extends Component<updateLicenseProps, updateLicenseState> {
  constructor(props: updateLicenseProps) {
    super(props);
    this.state = {
      newLicense: "",
      formError: "",
    };
  }

  onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const { newLicense } = this.state;
    const { emailId, password } = this.props;
    if (newLicense) {
      const config = {
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.post(
          "/api/users/updateLicense",
          { emailId, password, newLicense },
          config
        );

        localStorage.setItem("token", response.data.token);
        if (localStorage.getItem("token")) {
          this.props.history.push("/downloads");
        }
      } catch (error: any) {
        this.setState({ formError: error.response.data.msg });
      }
    } else {
      this.setState({ formError: "Please Enter A License Key. " });
    }
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        Please Enter a New License Key
        <div className="form-group mt-2">
          <label htmlFor="newLicense">License Key</label>
          <input
            type="text"
            name="newLicense"
            className="form-control"
            value={this.state.newLicense}
            onInput={(event: FormEvent<HTMLInputElement>) => {
              this.setState({ newLicense: event.currentTarget.value });
            }}
          ></input>
        </div>
        {this.state.formError && (
          <p className="text-danger mb-0">{this.state.formError}</p>
        )}
        <button type="submit" className="btn btn-dark mt-4">
          Update License
        </button>
      </form>
    );
  }
}

export default withRouter(UpdateLicense);
