import React, { Component, FormEvent } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../data/baseURL";

interface State {
  verificationCode: string;
  formError: string;
  message: string;
}

class VerificationCode extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      verificationCode: "",
      formError: "",
      message: "",
    };
  }

  onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();
    if (!this.state.verificationCode) {
      this.setState({ formError: "Please Enter Your Verification Code!" });
    } else {
      const token = localStorage.getItem("token") || "";
      if (token) {
        try {
          //send code through api
          const config = {
            baseURL: baseURL,
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": token,
            },
          };

          const response = await axios.post(
            "/api/users/verifyEmail",
            { verificationCode: this.state.verificationCode },
            config
          );

          //check response
          if (response.data.verified) {
            this.props.history.push("/downloads");
            window.location.reload();
          }
        } catch (err: any) {
          console.log(err);
          this.setState({ formError: err.response.data.msg });
        }
      }
    }
  };

  resendCode = async (): Promise<void> => {
    try {
      const token = localStorage.getItem("token") || "";
      if (token) {
        //send code through api
        const config = {
          baseURL: baseURL,
          headers: {
            "x-auth-token": token,
          },
        };

        const response = await axios.get(
          "/api/users/resendEmailVerification",
          config
        );

        this.setState({ message: response.data.msg });
      }
    } catch (err: any) {
      console.log(err);
      this.setState({ message: err.response.data.msg });
    }
  };

  render() {
    return (
      <div>
        <p>
          A verification code was sent to the email you registered. Please copy
          and paste that code into the box below to finish registration.
        </p>
        <h5>Enter Verification Code Below</h5>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="verificationCode"
              className="form-control"
              value={this.state.verificationCode}
              onChange={(e: FormEvent<HTMLInputElement>) =>
                this.setState({ verificationCode: e.currentTarget.value })
              }
            />
            {this.state.formError && (
              <p className="text-danger mb-0">{this.state.formError}</p>
            )}
            <button type="submit" className="btn btn-dark mt-4">
              Submit Verification
            </button>
          </div>
        </form>
        {this.state.message ? (
          <p className="text-success mt-2 mb-0">{this.state.message}</p>
        ) : (
          <button className="mt-2 btn btn-light" onClick={this.resendCode}>
            Click Here to Resend Code
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(VerificationCode);
