import React, { Component, FormEvent, Fragment } from 'react';
import validator from 'validator';
import axios from 'axios';
import ReCaptcha from 'react-google-recaptcha';
import { withRouter, Link } from 'react-router-dom';
import { freeDomains } from '../../data/freeDomains';
import { baseURL } from '../../data/baseURL';

import VerificationCode from '../layout/VerificationCode';
import UpdateLicense from '../layout/UpdateLicense';
interface State {
  emailId: string;
  password: string;
  secondPassword: string;
  firstName: string;
  lastName: string;
  companyName: string;
  registrationCode: string;

  loginMode: boolean;
  formError: string;

  captchaSuccess: boolean;
  triggerCaptcha: boolean;

  disableForm: boolean;

  displayVerificationInput: boolean; // Email Verification Code Input Trigger

  displayUpdateLicenseInput: boolean;
}

class Authentication extends Component<any, State> {
  siteKey = '6LfNhjEkAAAAAL2iJCBURginE9wRzgBfjlJK0RM4';

  constructor(props: any) {
    super(props);
    this.state = {
      emailId: '',
      password: '',
      secondPassword: '',
      firstName: '',
      lastName: '',
      companyName: '',
      registrationCode: '',
      loginMode: true,
      formError: '',
      triggerCaptcha: false,
      captchaSuccess: false,
      disableForm: false,
      displayVerificationInput: false,
      displayUpdateLicenseInput: false,
    };
  }

  // Ensure the current ip attempting to login hasn't made too many attempts.
  checkBlocked = async () => {
    const config = {
      baseURL: baseURL,
    };
    try {
      const response = await axios.get('/api/auth/blocked', config);
      if (response.data.blocked) {
        this.setState({
          disableForm: true,
          formError: 'You have made too many attempts to log in. Please contact support.',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount = () => {
    this.checkBlocked();
  };

  // Switch form between login and input modes by changing the state login boolean
  switchForm = (): void => {
    this.setState({ loginMode: !this.state.loginMode });
  };

  // Validate that email input is an email and isn't a free email.
  validateEmail = (): boolean => {
    const regex = new RegExp('@', 'g');
    const emailDomain: string = this.state.emailId.slice(this.state.emailId.search(regex) + 1);

    if (freeDomains.find((domain: string) => domain === emailDomain)) {
      this.setState({ formError: 'Please enter a business email address.' });
      return false;
    } else if (!validator.isEmail(this.state.emailId)) {
      this.setState({ formError: 'Please enter a valid email address.' });
      return false;
    } else {
      this.setState({ formError: '' });
      return true;
    }
  };

  // validate password length 8 and that it contains a special character, 1 lower and 1 upper
  validateRegPassword = (): boolean => {
    if (!validator.isByteLength(this.state.password, { min: 8 })) {
      this.setState({
        formError: 'Password needs to be 8 characters long.',
        password: '',
        secondPassword: '',
      });
      return false;
    } else if (
      !validator.isStrongPassword(this.state.password, {
        minNumbers: 1,
        minSymbols: 1,
        minUppercase: 1,
        minLowercase: 0,
      })
    ) {
      this.setState({
        formError: 'Password needs to contain 1 uppercase, 1 number and 1 special character.',
        password: '',
        secondPassword: '',
      });
      return false;
    } else if (this.state.password !== this.state.secondPassword) {
      this.setState({
        formError: 'Passwords do not match.',
        password: '',
        secondPassword: '',
      });
      return false;
    } else {
      this.setState({ formError: '' });
      return true;
    }
  };

  // Validate License Key length 16
  validateRegCode = (): boolean => {
    if (!validator.isByteLength(this.state.registrationCode, { min: 29, max: 29 })) {
      this.setState({ formError: 'Invalid License Key.' });
      return false;
    } else {
      return true;
    }
  };

  // Validate Name fields as non-empty
  validateNames = (): boolean => {
    if (
      !validator.isEmpty(this.state.firstName) &&
      !validator.isEmpty(this.state.lastName) &&
      !validator.isEmpty(this.state.companyName)
    ) {
      return true;
    } else {
      this.setState({ formError: 'Please fill out all fields.' });
      return false;
    }
  };

  // Validate form input through usage of other validation functions
  validate = (): boolean => {
    let valid: boolean = false;
    if (this.state.loginMode) {
      valid = this.validateEmail();
      if (validator.isEmpty(this.state.password)) {
        valid = false;
        this.setState({ formError: 'Please fill out all fields.' });
      }
    } else {
      valid =
        this.validateEmail() &&
        this.validateRegPassword() &&
        this.validateNames() &&
        this.validateRegCode();
    }
    return valid;
  };

  // Post registration or login to api then return a login token and either redirect user or have them put in License Key.
  login = async (
    user:
      | { emailId: string; password: string }
      | {
          emailId: string;
          password: string;
          firstName: string;
          lastName: string;
          companyName: string;
          registrationCode: string;
        },
  ): Promise<any> => {
    const config = {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = this.state.loginMode
        ? await axios.post('/api/auth', user, config)
        : await axios.post('/api/users', user, config);

      localStorage.setItem('token', res.data.token);
      if (localStorage.getItem('token')) {
        if (this.state.loginMode) {
          this.props.history.push('/downloads');
        } else {
          this.setState({ disableForm: true, displayVerificationInput: true });
          //this.props.history.push("downloads");
        }
      }
    } catch (err: any) {
      this.setState({ triggerCaptcha: false });
      this.setState({ formError: err.response.data.msg });
      if (err.response.data.msg === 'Too many attempts. Please contact SPJ support to continue.') {
        this.setState({ disableForm: true });
      } else if (err.response.data.msg === 'License Key Is Expired.') {
        this.setState({ disableForm: true, displayUpdateLicenseInput: true });
      }
      console.log(err.response.data.msg);
    }
  };

  // validate form and trigger captia
  onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    localStorage.removeItem('token');
    if (this.validate()) {
      this.setState({ formError: '' });
      this.setState({ triggerCaptcha: true });
    } else {
      console.log('Authentication Failed.');
    }
  };

  //ReCaptcha Callback to verify captcha-token response generated by user.
  reCaptchaCallback = async (token: string | null): Promise<any> => {
    if (token) {
      try {
        const config = {
          baseURL: baseURL,
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const response = await axios.post('/api/auth/captcha', { token: token }, config);

        this.setState({ captchaSuccess: response.data.success });

        if (this.state.captchaSuccess) {
          const { emailId, password, firstName, lastName, companyName, registrationCode } = this
            .state as State;
          const user = this.state.loginMode
            ? {
                emailId,
                password,
              }
            : {
                emailId,
                password,
                firstName,
                lastName,
                companyName,
                registrationCode,
              };
          this.login(user);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mt-4">cITopus Downloads Login</h2>
            <br />
            {!this.state.loginMode && (
              <div>
                <h5>Already have an account?</h5>
                <button className="btn btn-dark" onClick={this.switchForm}>
                  Click Here to Login
                </button>
              </div>
            )}

            {this.state.loginMode && (
              <div>
                <h5>Don't have an Account?</h5>
                <button className="btn btn-dark" onClick={this.switchForm}>
                  Click Here to Register
                </button>
              </div>
            )}
          </div>
          <div className="col-4 mt-5 offset-4">
            <h2>{this.state.loginMode ? 'Login' : 'Register'}</h2>

            {this.state.formError && <h5 className="text-danger">{this.state.formError}</h5>}
            {!this.state.disableForm && !this.state.triggerCaptcha && (
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    value={this.state.emailId}
                    onInput={(e: FormEvent<HTMLInputElement>) =>
                      this.setState({ emailId: e.currentTarget.value })
                    }
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={this.state.password}
                    onInput={(e: FormEvent<HTMLInputElement>) =>
                      this.setState({ password: e.currentTarget.value })
                    }
                  ></input>
                </div>
                {!this.state.loginMode && (
                  <div>
                    <div className="form-group">
                      <label htmlFor="secondPassword">Re-enter Password</label>
                      <input
                        type="password"
                        name="secondPassword"
                        className="form-control"
                        value={this.state.secondPassword}
                        onInput={(e: FormEvent<HTMLInputElement>) =>
                          this.setState({
                            secondPassword: e.currentTarget.value,
                          })
                        }
                      ></input>
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        value={this.state.firstName}
                        onInput={(e: FormEvent<HTMLInputElement>) =>
                          this.setState({ firstName: e.currentTarget.value })
                        }
                      ></input>
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        value={this.state.lastName}
                        onInput={(e: FormEvent<HTMLInputElement>) =>
                          this.setState({ lastName: e.currentTarget.value })
                        }
                      ></input>
                    </div>
                    <div className="form-group">
                      <label htmlFor="companyName">Company Name</label>
                      <input
                        type="text"
                        name="companyName"
                        className="form-control"
                        value={this.state.companyName}
                        onInput={(e: FormEvent<HTMLInputElement>) =>
                          this.setState({
                            companyName: e.currentTarget.value,
                          })
                        }
                      ></input>
                    </div>

                    {
                      //Uncomment this block for License Key Input
                      <div className="form-group">
                        <label htmlFor="registrationCode">License Key</label>
                        <input
                          type="text"
                          name="registrationCode"
                          className="form-control"
                          value={this.state.registrationCode}
                          onInput={(e: FormEvent<HTMLInputElement>) =>
                            this.setState({
                              registrationCode: e.currentTarget.value,
                            })
                          }
                        ></input>
                      </div>
                    }
                  </div>
                )}

                <button className="btn btn-dark mt-4" type="submit">
                  {this.state.loginMode ? 'Login' : 'Register'}
                </button>
                <div className="mt-2">
                  {this.state.loginMode && (
                    <Link to="PasswordReset" className="mt-2">
                      Forgot Password?
                    </Link>
                  )}
                </div>
              </form>
            )}
            {this.state.triggerCaptcha && (
              <div className="mt-4">
                <ReCaptcha
                  sitekey={this.siteKey}
                  onChange={token => this.reCaptchaCallback(token)}
                />
              </div>
            )}
            {this.state.displayVerificationInput && <VerificationCode />}
            {this.state.displayUpdateLicenseInput && (
              <UpdateLicense emailId={this.state.emailId} password={this.state.password} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Authentication);
