import axios from "axios";
import React, { Component, FormEvent } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "../layout/Navbar";
import DownloadRow from "../layout/DownloadRow";
import { baseURL } from "../../data/baseURL";
import VerificationCode from "../layout/VerificationCode";

interface Download {
  type: string;
  version: string;
  build: string;
  releaseDate: string;
  path: string;
  name: string;
}

interface State {
  verified: boolean;
  showVerificationInput: boolean;
  platformDownloads: Array<Download> | null;
  utilityDownloads: Array<Download> | null;
  error: string;
  search: string;
  searchResultsPlatform: Array<Download> | null | undefined;
  searchResultsUtility: Array<Download> | null | undefined;
}

class Downloads extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      verified: false,
      showVerificationInput: false,
      platformDownloads: null,
      utilityDownloads: null,
      error: "",
      search: "",
      searchResultsPlatform: null,
      searchResultsUtility: null,
    };
  }

  // Redirect user after 3 seconds allowing them to read the redirect.
  redirectTimeout = () => {
    this.setState({
      error: "Authorization denied. You are being redirected to the login.",
    });
    setTimeout(() => {
      this.props.history.push("/");
    }, 3000);
  };

  // Check that the user has a token and it is valid.
  checkVerification = async (): Promise<any> => {
    const token = localStorage.getItem("token") || "";

    if (token) {
      try {
        const config = {
          baseURL: baseURL,
          headers: {
            "x-auth-token": token,
          },
        };
        const response = await axios.get("/api/auth/verifyToken", config);
        if (response.data.verified) {
          this.setState({
            verified: true,
          });
        } else {
          if (response.data.msg === "Has not verified email.") {
            this.setState({
              error:
                "In order to finish registration you must verify your email.",
              showVerificationInput: true,
            });
          } else {
            this.redirectTimeout();
          }
        }
      } catch (err) {
        console.log(err);
        this.redirectTimeout();
      }
    } else {
      this.redirectTimeout();
    }
  };

  // Fetch an array of download objects from the api

  fetchDownloadList = async () => {
    const token = localStorage.getItem("token") || "";

    if (token) {
      try {
        const config = {
          baseURL: baseURL,
          headers: {
            "x-auth-token": token,
          },
        };
        const response = await axios.get("/api/downloads/list", config);
        const downloadList = response.data.downloads;

        if (downloadList) {
          this.setState({
            platformDownloads: downloadList.filter(
              (download: Download) => download.type === "Platform"
            ),
            utilityDownloads: downloadList.filter(
              (download: Download) => download.type === "Utility"
            ),
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (this.state.platformDownloads?.length === 0) {
          // It seems as if the first time this executes files aren't loaded so automatically reload after 2 seconds.
          // TODO: Fix that problem in a more direct manner.
          setTimeout(this.fetchDownloadList, 2000);
        }
      }
    } else {
      console.log("No Token Access Denied.");
    }
  };

  componentDidMount = () => {
    this.checkVerification();
  };

  componentDidUpdate = () => {
    if (this.state.verified && !this.state.platformDownloads) {
      this.fetchDownloadList();
    }
  };

  // On Change handler for search input.
  onChangePlatform = (e: FormEvent<HTMLInputElement>) => {
    const regex = new RegExp(e.currentTarget.value, "gi");
    this.setState({
      search: e.currentTarget.value,
      searchResultsPlatform: this.state.platformDownloads?.filter(
        (download: Download) => {
          return (
            download.type.match(regex) ||
            download.build.match(regex) ||
            download.name.match(regex)
          );
        }
      ),
    });
  };

  onChangeUtility = (e: FormEvent<HTMLInputElement>) => {
    const regex = new RegExp(e.currentTarget.value, "gi");
    this.setState({
      search: e.currentTarget.value,
      searchResultsUtility: this.state.utilityDownloads?.filter(
        (download: Download) => {
          return (
            download.type.match(regex) ||
            download.build.match(regex) ||
            download.name.match(regex)
          );
        }
      ),
    });
  };

  render() {
    return (
      <div>
        {this.state.verified ? (
          <div>
            <Navbar />
            <div className="row">
              <div className="col-6 offset-3">
                <h2 className="my-4">Downloads</h2>

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="platform-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#platform"
                      type="button"
                      role="tab"
                      aria-controls="platform"
                      aria-selected="true"
                    >
                      Platform
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="utility-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#utility"
                      type="button"
                      role="tab"
                      aria-controls="utility"
                      aria-selected="false"
                    >
                      Utility
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="platform"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control mt-1"
                        value={this.state.search}
                        onChange={this.onChangePlatform}
                        placeholder="Search..."
                      ></input>
                    </div>

                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th scope="col">Appliance Type</th>
                          <th scope="col">Version</th>
                          <th scope="col">Build</th>
                          <th scope="col">Release Date</th>
                          <th scope="col" className="text-center">
                            Download
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.searchResultsPlatform
                          ? this.state.platformDownloads?.map(
                              (download: Download) => (
                                <DownloadRow
                                  download={download}
                                  key={
                                    download.type +
                                    this.state.platformDownloads?.indexOf(
                                      download
                                    )
                                  }
                                />
                              )
                            )
                          : this.state.searchResultsPlatform?.map(
                              (download: Download) => (
                                <DownloadRow
                                  download={download}
                                  key={
                                    download.type +
                                    this.state.platformDownloads?.indexOf(
                                      download
                                    )
                                  }
                                />
                              )
                            )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="utility"
                    role="tabpanel"
                    aria-labelledby="utility-tab"
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control mt-1"
                        value={this.state.search}
                        onChange={this.onChangeUtility}
                        placeholder="Search..."
                      ></input>
                    </div>

                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th scope="col">Appliance Type</th>
                          <th scope="col">Version</th>
                          <th scope="col">Build</th>
                          <th scope="col">Release Date</th>
                          <th scope="col" className="text-center">
                            Download
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.searchResultsUtility
                          ? this.state.utilityDownloads?.map(
                              (download: Download) => (
                                <DownloadRow
                                  download={download}
                                  key={
                                    download.type +
                                    this.state.utilityDownloads?.indexOf(
                                      download
                                    )
                                  }
                                />
                              )
                            )
                          : this.state.searchResultsUtility?.map(
                              (download: Download) => (
                                <DownloadRow
                                  download={download}
                                  key={
                                    download.type +
                                    this.state.utilityDownloads?.indexOf(
                                      download
                                    )
                                  }
                                />
                              )
                            )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <h3 className="text-danger">{this.state.error}</h3>
          </div>
        )}
        {this.state.showVerificationInput && (
          <div className="col-4 mt-5">
            <VerificationCode />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Downloads);
