import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { baseURL } from "../../data/baseURL";
import Navbar from "../layout/Navbar";

interface State {
  verified: boolean;
  error: string;
}

class ReleaseNotes extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      verified: false,
      error: "",
    };
  }

  redirectTimeout = () => {
    this.setState({
      error: "Authorization denied. You are being redirected to the login.",
    });
    setTimeout(() => {
      this.props.history.push("/");
    }, 3000);
  };

  checkVerification = async (): Promise<any> => {
    const token = localStorage.getItem("token") || "";

    if (token) {
      try {
        const config = {
          baseURL: baseURL,
          headers: {
            "x-auth-token": token,
          },
        };
        const response = await axios.get("/api/auth/verifyToken", config);
        if (response.data.verified) {
          this.setState({
            verified: true,
          });
        } else {
          this.redirectTimeout();
        }
      } catch (err) {
        console.log(err);
        this.redirectTimeout();
      }
    } else {
      this.redirectTimeout();
    }
  };

  componentDidMount = () => {
    this.checkVerification();
  };
  render() {
    return (
      <div>
        {this.state.verified ? (
          <div>
            <Navbar />
            <div className="row">
              <div className="col-10 offset-3">
                <h2 className="mt-4">Release Notes</h2>
                <a href="./download/ReleaseNotes.pdf" target="_blank">
                  Click Here to Download the Release Notes
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <h3 className="text-danger">{this.state.error}</h3>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ReleaseNotes);
