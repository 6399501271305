import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class Navbar extends Component<any> {
  constructor(props: any) {
    super(props);
  }
  onLogout = () => {
    localStorage.removeItem("token");
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="row bg-dark text-white">
        <div className="col-4 offset-3">
          <nav className="navbar navbar-expand">
            <div className="navbar-nav">
              <Link className="text-white nav-link" to="/downloads">
                Downloads
              </Link>
              <Link className="text-white nav-link" to="/releaseNotes">
                Release Notes
              </Link>
            </div>
          </nav>
        </div>
        <div className="col-1 offset-1 my-auto">
          <button
            className="btn  btn-secondary offset-5 mr-auto offset-2 text-center nav-item py-0"
            onClick={this.onLogout}
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
